import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { height } from '@material-ui/system';

const useStyles = makeStyles((theme) => ({
    sliderContainer: {
        position: 'relative',
      
    },
    carouselBack: {
        left: '1%',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%);',
        all: 'unset',
        cursor: 'pointer',
        backgroundColor:'black',
        color:'white',
    
        width:'40px',
        height:'40px',
        '&:focus': {
         
        }
    },
    carouselNext: {
        right: '1%',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%);',
        all: 'unset',
        cursor: 'pointer',
        backgroundColor:'black',
        color:'white',
    
        width:'40px',
        height:'40px',
        '&:focus': {
    
        }
    },
    carouselDots: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px',
        marginBottom: '15px',
        '& > button': {
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            border: 'none',
            padding: '0',
            cursor: 'pointer',
            backgroundColor: '#fc3',
            marginRight: '10px',
        }
    },
    backgroundImage: {
        backgroundPosition: 'center',
        backgroundSize: '100% 100%!important',
    
        '@media (max-width: 624px)': {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
            height: '36%'
        },
    },    


}));

const Carousel = ({ slides, innerClass, dots, ...props }) => {
    const classes = useStyles();

    const handleChange =(index) =>{
        props.setActiveImg(index)
    }

    return (
        <CarouselProvider
            {...props}
        >
            <div className={classes.sliderContainer}>
                <Slider>
                    {
                        slides && slides.map((slide, index) =>
                            <Slide innerClassName={innerClass} className={classes.slide} index={index}>
                                <div className={classes.content}>{slide.content}</div>
                                {slide.content ? <Image tag="div" isBgImage src={slide.img ? slide.img : slide} className={classes.backgroundImage} height="100vh"/> :
                                    <Image src={slide.img ? slide.img : slide} height="100vh" onClick={()=>{handleChange(index)}}/>}
                            </Slide>
                        )
                    }
                </Slider>
                {dots && <DotGroup className={classes.carouselDots} disableActiveDots={false} />}
                <ButtonBack className={classes.carouselBack}><ChevronLeftIcon style={{ fontSize: 40 }} /></ButtonBack>
                <ButtonNext className={classes.carouselNext}><ChevronRightIcon style={{ fontSize: 40 }} /></ButtonNext>

            </div>
        </CarouselProvider>
    )
}

export default Carousel;