import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
//import contactUsImage from '../shared/assets/FURY_PMB_SUZUKI_MARCH_2021_CONTACT_PAGE_BANNER.jpg'
import { ImageContainer } from "../shared/ImageContainer";
import ContactComponent from "../shared/ContactComponent";
import ContactDetailsController from "./ContactDetailsController";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
  dividerTitle: {
    backgroundColor: "#EFDF00",
    width: "33%",
    marginBottom: "0px",
    height: "3px",
    margin: "20px auto",
  },
  bold: {
    fontWeight: 700,
  },
  url: {
    color: "#7c7c7c",
    textDecoration: "none",
    "&:hover": {
      color: "#1f2532",
      textDecoration: "none",
    },
  },
  link: {
    backgroundColor: "#EFDF00",
    marginBottom: "1rem",
    color: "#ffffff",
    fontWeight: "700",
    "&:hover": {
      textDecoration: "none",
      opacity: 0.8,
    },
  },
  Anchor:{
    textDecoration:'none',
    color: "#EFDF00",
  }
}));

const ContactUs = () => {
  const classes = useStyles();
  const { dealerContactDetails, contactDetailsLoading } =
    ContactDetailsController();

  let streetAddress;
  let streetAddress2;
  let city;
  let postalCode;

  let splitAddress = dealerContactDetails?.address?.streetAddress.split(",");
  if (!contactDetailsLoading && splitAddress) {
    streetAddress = splitAddress[0];
    streetAddress2 = splitAddress[1];
    city = splitAddress[2];
    postalCode = dealerContactDetails?.address?.streetAddressPostal;
  }
  return (
    <Container maxWidth="lg">
      <Box>
        {/*<ImageContainer aspectRatio={(16/6)} height='100%' src={} alt='Contact Us' />*/}
      </Box>
      <div className={classes.content}>
        <Typography
          gutterBottom
          variant="h5"
          align="center"
          color="textPrimary"
          fontWeight="500"
        >
          Our Customer Relations team are here to help you
        </Typography>
        <Divider className={classes.dividerTitle} />
        <Box mb={3} mt={3}>
          <Box textAlign="center" fontSize={"1.5rem"}>
            Send us your details and we will call you
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box fontWeight="fontWeightBold">General Enquiries</Box>
            <Box mb={3}><a className={classes.Anchor} href={"tel:"+ dealerContactDetails?.newCarsPhoneNumber}>{dealerContactDetails?.newCarsPhoneNumber}</a></Box>
            <Box fontWeight="fontWeightBold">Address</Box>
            <Box mb={3}>
              {[
                streetAddress,
                streetAddress2,
                city,
                postalCode
              ]
              .filter(line => line)
              .map(line => (
              <>
                {line.trim()}
              <br/>
              </>
              ))}
            </Box>

            <Box fontWeight="fontWeightBold">Email</Box>
            <Box mb={3}>
              <a
                className={classes.url}
                href={`mailto:${dealerContactDetails?.email}`}
                title="Drop us message on email"
              >
                {dealerContactDetails?.email}
              </a>
            </Box>
            <Box fontWeight="fontWeightBold">Opening Hours</Box>
            <Box>Monday - Friday 07:30 - 17:30</Box>
            <Box>Saturday: 09:00 - 13:00</Box>
            <Box mb={3}>Sunday: Closed</Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <ContactComponent leadTypeId={3} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default ContactUs;
