import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ImageContainer } from "../shared/ImageContainer";
import { CurrencyValue } from "../shared/TextMask";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import VehicleController from "../usedVehicles/VehicleController";
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { Modal } from "@material-ui/core";
import CarProfileContainer from "../carProfile/UsedCar";
import UsedVehicles from '../usedVehicles/UsedVehicles';
import { useParams, useHistory } from "react-router-dom";
import { PAGE_SIZE, MOTORGROUPID } from '../shared/Constants'

const useStyles = makeStyles((theme) => ({
    filterLoadingContainer: {
        height: "576px",
        position: "absolute",
        width: "210px",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        zIndex: "10",
        boxShadow: "inset 0 0 0 200px rgb(255 255 255 / 5%)",
        filter: "blur(10px)",
    },
    full: {
        width: "100%",
    },
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6, 3, 6),
    },
    dividerTitle: {
        backgroundColor: "#06141f",
        width: "33%",
        marginBottom: "30px",
        height: "3px",
        margin: "20px auto",
    },
    carddividerTitle: {
        backgroundColor: "#06141f",
        width: "100%",
        marginBottom: "10px",
        height: "3px",
        margin: "5px auto",
    },
    bold: {
        fontWeight: 700,
    },
    lighter: {
        fontWeight: "lighter",
    },
    flex: {
        display: "flex",
        flexDirection: "row!important",
        justifyContent: "space-between",
    },

    clear: {
        padding: "0px!important",
    },
    price: {
        fontSize: "1.5rem",
        "&>span": {
            fontWeight: 400,
        },
    },
    pricepm: {
        fontSize: "0.8rem",
        "&>span": {
            fontWeight: 400,
        },
    },
    title: {
        fontSize: "1.0rem",
        "&>span": {
            fontWeight: 400,
        },
    },
    vehicleContainer: {
        "& h5, & p": {
            color: "#7c7c7c",
        },
        "&:hover img, &:hover p, &:hover h5": {
            color: "#1f2532",
            cursor: "pointer",
            textDecoration: "none",
        },
    },
    btn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        marginTop: "2%",
        width: "45%",
    },
    btn1: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        marginTop: "2%",
        width: "45%",
        background: "white",
        border: "1px solid black",
    },
    btn2: {
        minWidth: "100%",
    },
    image: {
        "& > div > div": {
            backgroundColor: "#ffffff !important",
        },
    },
    carsLoadContainer: {
        display: "flex !important",
        justifyContent: "center",

    },
    CarsLoad: {
        display: 'inherit !important',
        margin: 'auto',
        marginTop: '14%'
    },
    flexTop: {
        display: "flex",
        flexDirection: "column!important",
        justifyContent: "space-between",
    },
    center: {
        display: "flex",
        flexDirection: "column!important",
        margin: '0 auto',
    },
    flex: {
        width:'50%',
        display: "flex",
        flexDirection: "row!important",
        justifyContent: "space-between",

    },
    btn1: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        width: "250px",
        background: "white",
        border: "1px solid black",
        paddingLeft: '40px',
        paddingRight: '40px',
        marginTop:'50px'

      },
    btnLoadMoreContainer:{
        display:'flex',
        justifyContent:'center',
        with:'100%'
    }

}));

const UsedCarUsedVehiclesPage = () => {

    const history = useHistory();
    const classes = useStyles();
    const [makes, setMake] = useState([]);
    const [priceRages, setPriceRanges] = useState([]);
    const [activeMake, setActiveMake] = useState(null);
    const [activeModel, setActiveModel] = useState(null);
    const [activeProvince, setActiveProvince] = useState(null);
    const [activePriceRange, setActivePriceRange] = useState(null);
    const [activeYear, setActiveYear] = useState(null);
    const [activeTransmission, setActiveTransmission] = useState(null);
    const [activeNewUsed, setActiveNewUsed] = useState(null);
    const [activeActiveBodyType, setActiveBodyType] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [showFilteredData, setShowFilteredData] = useState(false);
    const [activeVehicle, setActiveVehicle] = useState(null);
    const [activePrices, setActivePrices] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [activePageIndex, setAcivePageIndex] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const { loading, error, vehicles, total, pagedVehicles, setActivePageSize } = VehicleController();
    let { stockId } = useParams();
  
    const onGoToPage = (index) =>{
        setAcivePageIndex(index);
    }
    const onNextPage = () =>{
        if(activePageIndex+1 >= pagedVehicles.length) return
        setAcivePageIndex(activePageIndex + 1);
        window.scrollTo(0,200);
    }
    const onPreviousPage = () =>{
        if(activePageIndex === 0) return;
        setAcivePageIndex(activePageIndex - 1);
        window.scrollTo(0,200);
    }
    useEffect(() => {

        if (vehicles.length > 0 && stockId && (activeVehicle?.stockId !== stockId)) {
            var vehicle = vehicles.find((v) => {
                return v.stockId === parseInt(stockId)
            });

            if (vehicle)
                setActiveVehicle(vehicle);
        }

        if (vehicles.length > 0) {
            setFilteredData(vehicles);
            setMake(Array.from(new Set(vehicles.map((m) => m.brand))));
            const prices = [...new Set(vehicles.map((m) => m.price))];
            CalcPriceRanges(prices, true);
        }
    }, [vehicles]);

    const onSetFilterCriteria = (make, model, priceRange, province, year, transmission, newUsed, bodytype) => {
        setSearchLoading(true);
        setActiveMake(make);
        setActiveModel(model);
        setActivePriceRange(priceRange);
        setActiveProvince(province);
        setActiveYear(year);
        setActiveTransmission(transmission);
        setActiveNewUsed(newUsed);
        setActiveBodyType(bodytype);
        setShowFilteredData(true);
        setActiveVehicle(null);

        const filteredData = vehicles
            .filter((v) => {
                return activeMake
                    ? v.brand.toUpperCase() === activeMake.toUpperCase()
                    : v;
            })
            .filter((v) => {
                return activeModel
                    ? v.model.toUpperCase() === activeModel.toUpperCase()
                    : v;
            })
            .filter((v) => {
                return activePriceRange
                    ? v.price >= activePriceRange.min && v.price <= activePriceRange.max
                    : v;
            })
            .filter((v) => {
                return activeProvince
                    ? v.province.toUpperCase() === activeProvince.toUpperCase()
                    : v;
            })
            .filter((v) => {
                return activeYear ? v.year === activeYear : v;
            })
            .filter((v) => {
                return activeTransmission
                    ? v.transmission.toUpperCase() === activeTransmission.toUpperCase()
                    : v;
            })
            .filter((v) => {
                return activeNewUsed
                    ? v.newUsed.toUpperCase() === activeNewUsed.toUpperCase()
                    : v;
            })
            .filter((v) => {
                return activeActiveBodyType
                    ? v.bodyType.toUpperCase() === activeActiveBodyType.toUpperCase()
                    : v;
            });
        if (filteredData?.length > 0) {
            const prices = [...new Set(filteredData.map((m) => m.price))];
            setPriceRanges(CalcPriceRanges(prices));
        }

        setFilteredData(filteredData);
        setSearchLoading(false);
    }

    useEffect(() => {

        setSearchLoading(true);

        const filteredData = vehicles
            .filter((v) => {
                return activeMake
                    ? v.brand.toUpperCase() === activeMake.toUpperCase()
                    : v;
            })
            .filter((v) => {
                return activeModel
                    ? v.model.toUpperCase() === activeModel.toUpperCase()
                    : v;
            })
            .filter((v) => {
                return activePriceRange
                    ? v.price >= activePriceRange.min && v.price <= activePriceRange.max
                    : v;
            })
            .filter((v) => {
                return activeProvince
                    ? v.province.toUpperCase() === activeProvince.toUpperCase()
                    : v;
            })
            .filter((v) => {
                return activeYear ? v.year === activeYear : v;
            })
            .filter((v) => {
                return activeTransmission
                    ? v.transmission.toUpperCase() === activeTransmission.toUpperCase()
                    : v;
            })
            .filter((v) => {
                return activeNewUsed
                    ? v.newUsed.toUpperCase() === activeNewUsed.toUpperCase()
                    : v;
            })
            .filter((v) => {
                return activeActiveBodyType
                    ? v.bodyType.toUpperCase() === activeActiveBodyType.toUpperCase()
                    : v;
            });
        if (filteredData?.length > 0) {
            const prices = [...new Set(filteredData.map((m) => m.price))];
            setPriceRanges(CalcPriceRanges(prices));
        }

        setFilteredData(filteredData);
        setSearchLoading(false);

    }, [
        activeMake,
        activeModel,
        activeProvince,
        activePriceRange,
        activeYear,
        activeTransmission,
        activeNewUsed,
        activeActiveBodyType,
        vehicles,
    ]);
     const currentPage =  activePageIndex+1;
    const GetList = (
        make,
        model,
        priceRange,
        province,
        year,
        transmission,
        newUsed,
        body
    ) => {
        return vehicles
            .filter((v) => {
                return make ? v.brand.toUpperCase() === make.toUpperCase() : v;
            })
            .filter((v) => {
                return model ? v.model.toUpperCase() === model.toUpperCase() : v;
            })
            .filter((v) => {
                return priceRange
                    ? v.price >= priceRange.min && v.price < priceRange.max
                    : v;
            })
            .filter((v) => {
                return province
                    ? v.province.toUpperCase() === province.toUpperCase()
                    : v;
            })
            .filter((v) => {
                return year ? v.year === year : v;
            })
            .filter((v) => {
                return transmission
                    ? v.transmission.toUpperCase() === transmission.toUpperCase()
                    : v;
            })
            .filter((v) => {
                return newUsed ? v.newUsed.toUpperCase() === newUsed.toUpperCase() : v;
            })
            .filter((v) => {
                return body ? v.bodyType.toUpperCase() === body.toUpperCase() : v;
            });
    };

    const CalcPriceRanges = (list, update) => {
        const min = Math.min.apply(Math, list);
        const max = Math.max.apply(Math, list);
        let step = 0;
        var ranges = [];

        while (true) {
            if (min > step) {
                if (step < 100000) {
                    step += 100000;
                } else {
                    step += 100000;
                }
            } else {
                if (step >= 100000) {
                    ranges.push({ min: step - 100000, max: step });
                } else {
                    if (step === 0) {
                        step += 100000;
                        ranges.push({ min: 0, max: step });
                    } else {
                        ranges.push({ min: step - 100000, max: step });
                    }
                }
                break;
            }
        }
        while (true) {
            if (step < max) {
                step += 100000;
                ranges.push({ min: step - 100000, max: step });
            } else {
                break;
            }
        }

        return ranges;
    };

    const onClearFilter = () => {
        setFilteredData([]);
        setShowFilteredData(false);
        window.location.reload();
    };

    const onSearch = () => {
        window.history.pushState({}, null, '/used-vehicles')
        setActiveVehicle(null);
        setShowFilteredData(true);
    };

    const onSelectVehicle = (vehicle) => {
        history.push(`/stock/${vehicle?.stockId}`)
        setActiveVehicle(vehicle);
    }
    return (
        <MuiThemeProvider>

            <Container maxWidth="lg" className={classes.content}>
                <Grid className={classes.flexTop}>
                    <Typography
                        gutterBottom
                        variant="h5"
                        align="center"
                        color="textPrimary"
                        fontWeight="500"
                    >
                        USED VEHICLES
                    </Typography>
                    <Typography
                        gutterBottom
                        variant="h6"
                        align="center"
                        color="textPrimary"
                        fontWeight="500"
                    >
                        Discover our large range of used vehicles
                    </Typography>
                    <Box mb={3} mt={3}>
                        <Typography align="center" classes={{ root: classes.bold }}>
                            DISCOVER RENAULT
                        </Typography>
                    </Box>
                    <Divider className={classes.dividerTitle} />
                </Grid>


                <Grid container maxWidth="lg" md={12}  >
                    <Grid md={2} xs={11} className={classes.center}>
                        {searchLoading && (
                            <div className={classes.filterLoadingContainer} />
                        )}
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="dealerId"
                            select
                            label="All Makes"
                            onChange={(e) => setActiveMake(e.target.value)}
                            disabled={Array.from(new Set(vehicles.map((m) => m.brand))).length === 0}
                        >
                            {makes.sort().map((option, i) => (
                                <MenuItem key={i} id={i} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            variant="outlined"
                            fullWidth
                            id="dealerId"
                            select
                            label="Any model"
                            onChange={(e) => setActiveModel(e.target.value)}
                            onCompositionStart={() => setActiveModel(null)}
                            disabled={Array.from(new Set(vehicles.map((m) => m.model))).length === 0}
                        >
                            {Array.from(
                                new Set(GetList(activeMake).map((v) => v.model))
                            ).sort().map((option, i) => (
                                <MenuItem key={i} id={i} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            variant="outlined"
                            fullWidth
                            id="dealerId"
                            select
                            label="All prices ranges"
                            onChange={(e) => setActivePriceRange(e.target.value)}
                            disabled={Array.from(new Set(vehicles.map((m) => m.price))).length === 0}
                        >
                            {priceRages.map((option, i) => (
                                <MenuItem key={i} id={i} value={option}>
                                    {`R${option?.min} - R${option?.max - 1}`}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            variant="outlined"
                            fullWidth
                            id="dealerId"
                            select
                            label="Any year"
                            onChange={(e) => setActiveYear(e.target.value)}
                            disabled={Array.from(new Set(vehicles.map((m) => m.year))).length === 0}
                        >
                            {Array.from(
                                new Set(
                                    GetList(
                                        activeMake,
                                        activeModel,
                                        activePriceRange,
                                        activeProvince
                                    ).map((v) => v.year)
                                )
                            ).sort().map((option, i) => (
                                <MenuItem key={i} id={i} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            variant="outlined"
                            fullWidth
                            id="dealerId"
                            select
                            label="Man / Auto"
                            defaultValue={null}
                            onChange={(e) => setActiveTransmission(e.target.value)}
                            disabled={Array.from(new Set(vehicles.map((m) => m.transmission))).length === 0}
                        >
                            {Array.from(
                                new Set(
                                    GetList(
                                        activeMake,
                                        activeModel,
                                        activePriceRange,
                                        activeProvince,
                                        activeYear
                                    ).map((v) => v.transmission)
                                )
                            ).filter(t => t.length > 0).sort().map((option, i) => (
                                <MenuItem key={i} id={i} value={option}>
                                    {option === 'M' ? 'Manual' : 'Automatic'}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            variant="outlined"
                            fullWidth
                            id="dealerId"
                            select
                            label="Used / Demo"
                            onChange={(e) => setActiveNewUsed(e.target.value)}
                            disabled={Array.from(new Set(vehicles.map((m) => m.newUsed))).length === 0}
                        >
                            {Array.from(
                                new Set(
                                    GetList(
                                        activeMake,
                                        activeModel,
                                        activePriceRange,
                                        activeProvince,
                                        activeYear,
                                        activeTransmission
                                    ).map((v) => v.newUsed)
                                )
                            ).sort().map((option, i) => (
                                <MenuItem key={i} id={i} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>

                        <Box mb={3} className={classes.flex}>
                            <Button
                                disabled={loading}
                                gutterBottom
                                variant="contained"
                                color="primary"
                                className={classes.full}
                                onClick={
                                    !showFilteredData
                                        ? () => onSearch()
                                        : () => onClearFilter()
                                }
                            >
                                {!searchLoading
                                    ? showFilteredData
                                        ? "RESET"
                                        : "SEARCH"
                                    : ""}
                                {searchLoading && <CircularProgress />}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid md={10} xs={12} classaName={classes.flex}>
                        {loading || searchLoading ? (
                            <Grid
                                classaName={classes.carsLoadContainer}

                                md={12}
                            >
                                <CircularProgress className={classes.CarsLoad} size={200} />
                            </Grid>
                        ) : (
                            <>
                                <Grid
                                    md={12}


                                >
                                    {activeVehicle ? <CarProfileContainer searchLoading={searchLoading} activeVehicle={activeVehicle} onFilterData={onSetFilterCriteria} />
                                        : <UsedVehicles vehicles={showFilteredData ? filteredData : (pagedVehicles.length>0?pagedVehicles[activePageIndex]:[])} onSelectView={onSelectVehicle} />}
                                </Grid>
                               {!showFilteredData &&
                                <Grid
                                    md={12}


                                    >
                                        {!activeVehicle &&
                                            <div className={classes.btnLoadMoreContainer}>
                                                <Box mb={3} className={classes.flex}>
                                                    <Button
                                                        variant="contained"
                                                        gutterBottom
                                                        className={classes.btn1}
                                                        onClick={() => onPreviousPage()}
                                                        disabled={activePageIndex === 0}
                                                    >
                                                        {`Previous (${currentPage - 1})`}
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        gutterBottom
                                                        className={classes.btn1}
                                                        onClick={() => onNextPage()}
                                                        disabled={activePageIndex + 1 === pagedVehicles.length}
                                                    >
                                                        {`Next (page ${currentPage + 1})`}
                                                    </Button>
                                                </Box>
                                            </div>
                                        }
                                </Grid>}

                            </>
                        )}
                    </Grid>
                </Grid>

            </Container>
        </MuiThemeProvider>
    );
};

export default UsedCarUsedVehiclesPage;
