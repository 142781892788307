import { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI, PAGE_SIZE, MOTORGROUPID } from '../shared/Constants'
import { number } from 'yup';

export default function VehicleController() {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [vehicles, setVehicles] = useState([])
    const [pagedVehicles,setPagedVehicle] = useState([])
    const [total, setTotal] = useState(false)
    const [activePageSize,setActivePageSize] = useState(null);

    const pageVehicles = ()=>{
        var numPages = total/PAGE_SIZE
        if(total % PAGE_SIZE !== 0){
            numPages = numPages - (numPages % 1);
        }

        var i =0;
        var tmpPagesData = []
        for(i = 0;i<numPages;i++){
            const index = i * PAGE_SIZE;
            tmpPagesData.push(vehicles.slice(index,index+PAGE_SIZE));
        }
        setPagedVehicle(tmpPagesData)
    }
    useEffect(()=>{
        if(total>0){
            pageVehicles();
        }
    },[total])

    useEffect(() => {

        let cancel
        setLoading(true)
        setError(false)
        setVehicles([])

        const params = {
            pageNumber: 1,
            motorGroupId: MOTORGROUPID,
            pageSize: 10000
        }

        axios({
            method: 'GET',
            url: `${BASE_URI}/stock`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {

            setVehicles(response.data?.list)
            setTotal(response.data?.total)
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            setLoading(false)
        })
        return () => cancel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { loading, error, vehicles, total,pagedVehicles }
}