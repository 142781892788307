import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  promotionContainer: {
    position: "absolute",
    height: "80%",
    padding: "0 0rem",
    left: "5%",
    lineHeight: "25px",
    "@media (max-width: 624px)": {
      position: "relative",
      height: "auto",
      left: "0%",
    },
    "@media (min-width: 625px) and (max-width: 1025px)": {
      left: "8%",
    },
  },
  content: {
    padding: "1rem 0.8rem",
    paddingRight: "2rem",
    backgroundColor: "#fbfbfbdd",
    minWidth: "350px",
  },
  vehicleName: {
    textTransform: "uppercase",
    "& > h4": {
      fontWeight: 400,
    },
  },
  vehiclePrice: {
    "& > h4": {
      fontWeight: 400,
    },
  },
  callToAction: {
    textTransform: "lowercase",
  },
}));

const SlidePromotion = ({
  vehicleName,
  name,
  tagline,
  price,
  callToAction,
  url,
}) => {
  const classes = useStyles();

  return (
    tagline && (
      <Box className={classes.promotionContainer}>
        <Box className={classes.content}>
          <Box mb={2}>
            <b dangerouslySetInnerHTML={{ __html: tagline }}></b>
          </Box>

          <Box className={classes.vehiclePrice}>
            <Typography variant="h4">{price}</Typography>
          </Box>
          <Box mt={1}>
            <Button
              variant="contained"
              color="primary"
              href={url}
              className={classes.callToAction}
            >
              {callToAction}
            </Button>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default SlidePromotion;
