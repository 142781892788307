import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FacebookIcon from "@material-ui/icons/Facebook";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TwitterIcon from "@material-ui/icons/Twitter";
import Box from "@material-ui/core/Box";
import DriveEtaOutlinedIcon from "@material-ui/icons/DriveEtaOutlined";
import HeadsetIcon from "@material-ui/icons/Headset";
import TextsmsIcon from "@material-ui/icons/Textsms";
import PlaceIcon from "@material-ui/icons/Place";
import { NavLink as RouterLink } from "react-router-dom";
import { useMediaQuery as mq } from "react-responsive";
import ContactDetailsController from "../contactUsPage/ContactDetailsController";
import FacebookLogo from "../shared/assets/logos/facebookLogo.png";

function Copyright() {
  return (
    <React.Fragment className="footerLink">
      {"© "}
      Renault Motors {new Date().getFullYear()}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: "flex",
  },
  icon: {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(1),
    color: "#000000",
  },
  list: {
    margin: 0,
    listStyle: "none",
    padding: 0,
  },
  listbottom: {
    margin: 0,
    listStyle: "none",
    padding: 0,
    display: "flex",
  },
  background: {
    backgroundColor: "#000000",
    display: "flex",
    margin: 0,
    listStyle: "none",
    padding: "10px 5%",
    maxWidth: "unset",
  },
  listbottom: {
    display: "flex",
    margin: 0,
    listStyle: "none",
    padding: "10px 20px",
  },
  bottomlistItem: {
    paddingRight: theme.spacing(2),
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
  footerIconSection: {
    backgroundColor: "#000000",
  },
  footerIconSectionLink: {
    color: "#ffffff",
  },
  footerIconSectionIcon: {
    color: "#EFDF00",
  },
  footerLink: {
    color: "#7c7c7c!important",
  },
  mb: {
    marginRight: "10px",
  },
  stickyBarInner: {
    backgroundColor: "#F8F8F8",
    textAlign: "center",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%",
  },
  stickyBar: {
    display: "block",
    padding: "20px",
    height: "60px",
    width: "100%",
  },
  stickyBarImage: {
    width: "100%",
    height: "100%",
  },
  imageIcon: {
    color: "white",
    width: "30px",
    height: "30px",
    margin: "auto",
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconWrapper: {
    background: "tranparent",
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    margin: "0",
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  list: {
    margin: 0,
    listStyle: "none",
    padding: 0,
  },
}));

const LANGUAGES = [
  {
    code: "en-US",
    name: "English",
  },
  {
    code: "fr-FR",
    name: "Français",
  },
];

export default function FooterComponent() {
  const classes = useStyles();
  const isMobile = mq({ query: "(max-width: 717px)" });
  const { dealerContactDetails, contactDetailsLoading } =
    ContactDetailsController();

  let streetAddress;
  let streetAddress2;
  let city;
  let postalCode;

  let splitAddress = dealerContactDetails?.address?.streetAddress.split(",");
  if (!contactDetailsLoading && splitAddress) {
    streetAddress = splitAddress[0];
    streetAddress2 = splitAddress[1];
    city = splitAddress[2];
    postalCode = dealerContactDetails?.address?.streetAddressPostal;
  }

  return (
    <Fragment>
      <Container className={classes.container}>
        <div class="addthis_inline_share_toolbox"></div>
      </Container>
      <Box className={classes.footerIconSection}>
        <Container className={classes.container}>
          <Grid container spacing={5} className={classes.footerIconSection}>
            <Grid item xs={12} sm={6} md={3} align="center">
              <Box mb={2}>
                <HeadsetIcon
                  className={classes.footerIconSectionIcon}
                  style={{ fontSize: 50 }}
                />
              </Box>
              <Typography className={classes.footerIconSectionLink}>
                Call us now
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} align="center">
              <Box mb={2}>
                <TextsmsIcon
                  className={classes.footerIconSectionIcon}
                  style={{ fontSize: 50 }}
                />
              </Box>
              <Typography className={classes.footerIconSectionLink}>
                Were Online
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} align="center">
              <Box mb={2}>
                <DriveEtaOutlinedIcon
                  className={classes.footerIconSectionIcon}
                  style={{ fontSize: 50 }}
                />
              </Box>
              <Typography className={classes.footerIconSectionLink}>
                Test drive
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} align="center">
              <Box mb={2}>
                <PlaceIcon
                  className={classes.footerIconSectionIcon}
                  style={{ fontSize: 50 }}
                />
              </Box>
              <Typography className={classes.footerIconSectionLink}>
                Nationwide delivery
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Typography component="footer" className={classes.root}>
        <Container className={classes.container}>
          <Box></Box>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={4}>
              {/* <Typography variant="h6" marked="left" gutterBottom>
                                Customer Service
                            </Typography> */}
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <Link className={classes.footerLink} href="/cookies">
                    Cookies
                  </Link>
                </li>
                <li className={classes.listItem}>
                  <Link className={classes.footerLink} href="/terms">
                    Terms & Conditions
                  </Link>
                </li>
                <li className={classes.listItem}>
                  <Link className={classes.footerLink} href="/privacy">
                    Privacy & Legal
                  </Link>
                </li>

                <li className={classes.listItem}>
                  <Link
                    className={classes.footerLink}
                    href="/apply-for-finance"
                  >
                    apply for finance
                  </Link>
                </li>

                <li className={classes.listItem}>
                  <Link className={classes.footerLink} href="/requestergws">
                    POPI Request
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Typography variant="h6" marked="left" gutterBottom>
                Company Information
              </Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <Box className={classes.footerLink}>
                    Reg Office: {streetAddress?.trim()} <br />{" "}
                    {streetAddress2?.trim()} <br /> {city?.trim()}
                    <br />
                    {postalCode?.trim()}
                  </Box>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
            <Typography
                className={classes.footerText}
                variant="h6"
                marked="left"
                gutterBottom
              >
                Connect Socially
              </Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <Box mb={2} className={classes.iconWrapper}>
                    <a href="https://www.facebook.com/renaultbethlehem">
                      <img src={FacebookLogo} className={classes.imageIcon} />
                    </a>
                  </Box>
                </li>
              </ul>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Grid item>
                <Copyright />
              </Grid>
              
            </Grid>
          </Grid>
        </Container>
      </Typography>
      <Grid
        spacing={5}
        className={`${classes.listbottom} ${classes.background}`}
        xs={12}
        sm={12}
        md={12}
      >
        <Grid xs={12} sm={6} md={8}></Grid>
        <Grid xs={12} sm={6} md={3} className={classes.footerLink} align="end">
          <Copyright />
        </Grid>
      </Grid>
    </Fragment>
  );
}
