import React, { useEffect, useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ImageContainerUsedVehicle } from "../shared/ImageContainerUsedVehicle";
import { CurrencyValue } from "../shared/TextMask";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import VehicleController from "./VehicleController";
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { Modal } from "@material-ui/core";
import CarProfileContainer from "../carProfile/UsedCar";
import { useHistory } from "react-router-dom";
import { FinanceContext } from "../shared/contexts/FinanceContext";
import ComingSoon from "../shared/assets/comingsoon.jpg";

const useStyles = makeStyles((theme) => ({
  filterLoadingContainer: {
    height: "576px",
    position: "absolute",
    width: "210px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: "10",
    boxShadow: "inset 0 0 0 200px rgb(255 255 255 / 5%)",
    filter: "blur(10px)",
  },

  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(20, 0, 6),
  },
  dividerTitle: {
    backgroundColor: "#EFDF00",
    width: "33%",
    marginBottom: "30px",
    height: "3px",
    margin: "20px auto",
  },
  carddividerTitle: {
    backgroundColor: "#EFDF00",
    width: "100%",
    marginBottom: "10px",
    height: "3px",
    margin: "5px auto",
  },
  bold: {
    fontWeight: 700,
  },
  lighter: {
    fontWeight: "lighter",
  },
  flex: {
    display: "flex",
    flexDirection: "row!important",
    justifyContent: "space-between",
  },
  flexWrap: {
    display: "flex",
    flexDirection: "row!important",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  flexTop: {
    display: "flex",
    flexDirection: "column!important",
    justifyContent: "space-between",
  },

  clear: {
    padding: "0px!important",
    fontSize: "14px",
  },
  price: {
    fontSize: "1.7rem",
    textAlign: "right",
    "&>span": {
      fontWeight: 400,
    },
  },
  pricepm: {
    fontSize: "0.8rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  title: {
    fontSize: "1.0rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  vehicleContainer: {
    margin: "0px 10px",
    "& h5, & p": {
      color: "#7c7c7c",
    },
    "&:hover img, &:hover p, &:hover h5": {
      color: "#1f2532",
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    width: "45%",
  },
  btn1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    width: "45%",
    background: "white",
    border: "1px solid black",
  },
  btn2: {
    minWidth: "100%",
  },
  image: {
    "& > div > div": {
      backgroundColor: "#ffffff !important",
    },
  },

  carsLoadContainer: {
    display: "flex",
    justifyContent: "center",
  },
  CarsLoad: {
    margin: "auto",
  },
}));

const UsedVehicles = ({ vehicles, onSelectView }) => {
  const history = useHistory();
  const classes = useStyles();
  let { setGlobalModel } = useContext(FinanceContext);

  const buildName = (vehicle) => {
    if (!vehicle) return "";

    return vehicle.brand + " " + vehicle.model;
  };

  const applyOnline = (vehicle) => {
    setGlobalModel(vehicle);

    history.push(`/apply-for-finance`);
  };
  return (
    <MuiThemeProvider>
      <Container container md={12} className={classes.flexWrap}>
        {vehicles &&
          vehicles.map((vehicle, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                {/*}  <Link
                        underline="none"
                        component={RouterLink}
                        to={"/stock/" + vehicle.stockId}
                  >*/}
                <Box className={classes.vehicleContainer}>
                  <Box
                    className={classes.image}
                    onClick={() => onSelectView(vehicle)}
                  >
                    <ImageContainerUsedVehicle
                      aspectRatio="auto auto"
                      height="222px"
                      position="relative"
                      src={vehicle.image ? vehicle.image : ComingSoon}
                      alt={buildName(vehicle.title)}
                    />
                  </Box>
                  <Box></Box>
                  <Box
                    mt={2}
                    mb={0}
                    textAlign="left"
                    component="h5"
                    className={`${classes.title} ${classes.bold}`}
                  >
                    {buildName(vehicle.title)}
                  </Box>

                  <Grid
                    item
                    md={12}
                    mt={0}
                    mb={0}
                    className={`${classes.flex} ${classes.bold}`}
                  >
                    <Grid item xs={12} sm={6} md={8}>
                      <p className="clear">{vehicle.brand}</p>
                      <p className="clear">{vehicle.model}</p>
                      <p className="clear">Stock No : {vehicle.stockNo}</p>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      component="h5"
                      justify="flex-end"
                      className={`${classes.price} ${classes.bold}`}
                    >
                      {vehicle?.price > 0 ? (
                        <>
                          <CurrencyValue value={vehicle.price} />
                        </>
                      ) : (
                        <>{"POA"}</>
                      )}
                    </Grid>
                  </Grid>
                  <Divider className={classes.carddividerTitle} />
                  <Box
                    mt={0}
                    mb={0}
                    textAlign="left"
                    component="p"
                    className={`${classes.title} ${classes.lighter} ${classes.flex}`}
                  >
                    <p className="clear">
                      {vehicle.year} | {vehicle.mileage} km | {vehicle.colour} |{" "}
                      {vehicle.transmission == "M" ? "Manual" : "Auto"}
                    </p>
                  </Box>

                  <Box mb={3} className={classes.flex}>
                    <Button
                      variant="contained"
                      gutterBottom
                      className={classes.btn1}
                      onClick={() => onSelectView(vehicle)}
                    >
                      VIEW
                    </Button>
                    <Button
                      variant="contained"
                      gutterBottom
                      className={classes.btn}
                      color="primary"
                      onClick={() => applyOnline(vehicle)}
                    >
                      APPLY ONLINE
                    </Button>
                  </Box>
                </Box>
              </Grid>
            );
          })}
      </Container>
    </MuiThemeProvider>
  );
};

export default UsedVehicles;
