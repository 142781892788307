import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useParams, useHistory } from "react-router-dom";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CurrencyValue } from './TextMask';
import * as vehiclesSpecs from './VehicleSpecs';

const tmpVariants = [
  {
    id: 0,
    title: '0.9 TURBO EXPRESSION A/C',
    price: 9700,
    specs: [
      {
        title: 'Service / Maintenance / Warranty',
        features: [
          { name: 'Service interval', value: '15 000 km' },
          { name: 'Service Plan', value: '24 months' },
          { name: 'Warranty', value: '5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE, 2 YEAR/30000KM SERVICE PLAN' }
        ]
      },
      {
        title: 'Active and Passive Safety',
        features: [
          { name: 'ABS', value: 'Yes' },
          { name: 'Electronic brakeforce distribution (EBD)', value: 'Yes' },
          { name: 'Air bags', value: '2' },
          { name: 'Autolock', value: 'Yes' },
          { name: 'ISOfix (secure child seat)', value: 'Yes' },
        ]
      },
      {
        title: 'Driving and Control Environment',
        features: [
          { name: 'Gearbox', value: 'MANUAL' },
          { name: 'Gears', value: '5' },
          { name: 'Drive', value: '2' },
          { name: 'Power steering', value: 'Yes' },
          { name: 'Steering adjustment (up down)', value: 'Yes' },
          { name: 'Steering adjustment (up down)', value: 'Yes' },
          { name: 'Multi function steering wheel', value: 'AUDIO, PHONE' },
          { name: 'Seats', value: '5' },
          { name: 'Spare tire size', value: 'FULLSIZE' },
          { name: 'Keyless Entry', value: 'Yes' },
        ]
      }
    ]
  },

  {
    id: 1,
    title: '0.9 TURBO STEPWAY EXPRESSION',
    price: 231900,
    specs: [
      {
        title: 'Service / Maintenance / Warranty',
        features: [
          { name: 'Service interval', value: '15 000 km' },
          { name: 'Service Plan', value: '24 months' },
          { name: 'Warranty', value: '5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE, 2 YEAR/30000KM SERVICE PLAN' }
        ]
      },
      {
        title: 'Active and Passive Safety',
        features: [
          { name: 'ABS', value: 'Yes' },
          { name: 'Electronic brakeforce distribution (EBD)', value: 'Yes' },
          { name: 'Air bags', value: '2' },
          { name: 'Autolock', value: 'Yes' },
          { name: 'ISOfix (secure child seat)', value: 'Yes' },
        ]
      },
      {
        title: 'Driving and Control Environment',
        features: [
          { name: 'Gearbox', value: 'MANUAL' },
          { name: 'Gears', value: '5' },
          { name: 'Drive', value: '2' },
          { name: 'Power steering', value: 'Yes' },
          { name: 'Steering adjustment (up down)', value: 'Yes' },
          { name: 'Steering adjustment (up down)', value: 'Yes' },
          { name: 'Multi function steering wheel', value: 'AUDIO, PHONE' },
          { name: 'Seats', value: '5' },
          { name: 'Spare tire size', value: 'FULLSIZE' },
          { name: 'Keyless Entry', value: 'Yes' },
        ]
      },
      {
        title: 'Interior Comfort',
        features: [
          { name: 'Air conditioner', value: 'Yes' },
          { name: 'Electric windows', value: 'Yes' },
          { name: 'Electric window detail', value: 'FRONT' },
          { name: 'Radio', value: 'Yes' },
          { name: 'Remote side mirrors', value: 'Yes' }
        ]
      }
    ]
  }
]

const tmpVariantsTriber = [
  {
    id: 0,
    title: '1.0 EXPRESSION',
    price: 183900,
    specs: [
      {
        title: 'Service / Maintenance / Warranty',
        features: [
          { name: 'Service interval', value: '15 000 km' },
          { name: 'Service Plan', value: '24 months' },
          { name: 'Warranty', value: '5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE, 2 YEAR/30000KM SERVICE PLAN' }
        ]
      },
      {
        title: 'Active and Passive Safety',
        features: [
          { name: 'ABS', value: 'Yes' },
          { name: 'Electronic brakeforce distribution (EBD)', value: 'Yes' },
          { name: 'Air bags', value: '2' },
          { name: 'Autolock', value: 'Yes' },
          { name: 'ISOfix (secure child seat)', value: 'Yes' },
        ]
      },
      {
        title: 'Driving and Control Environment',
        features: [
          { name: 'Gearbox', value: 'MANUAL' },
          { name: 'Gears', value: '5' },
          { name: 'Drive', value: '2' },
          { name: 'Power steering', value: 'Yes' },
          { name: 'Steering adjustment (up down)', value: 'Yes' },
          { name: 'Steering adjustment (up down)', value: 'Yes' },
          { name: 'Multi function steering wheel', value: 'AUDIO, PHONE' },
          { name: 'Seats', value: '5' },
          { name: 'Spare tire size', value: 'FULLSIZE' },
          { name: 'Keyless Entry', value: 'Yes' },
        ]
      }
    ]
  },

  {
    id: 1,
    title: '1.0 DYNAMIQUE',
    price: 193900,
    specs: [
      {
        title: 'Service / Maintenance / Warranty',
        features: [
          { name: 'Service interval', value: '15 000 km' },
          { name: 'Service Plan', value: '24 months' },
          { name: 'Warranty', value: '5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE, 2 YEAR/30000KM SERVICE PLAN' }
        ]
      },
      {
        title: 'Active and Passive Safety',
        features: [
          { name: 'ABS', value: 'Yes' },
          { name: 'Electronic brakeforce distribution (EBD)', value: 'Yes' },
          { name: 'Air bags', value: '2' },
          { name: 'Autolock', value: 'Yes' },
          { name: 'ISOfix (secure child seat)', value: 'Yes' },
        ]
      },
      {
        title: 'Driving and Control Environment',
        features: [
          { name: 'Gearbox', value: 'MANUAL' },
          { name: 'Gears', value: '5' },
          { name: 'Drive', value: '2' },
          { name: 'Power steering', value: 'Yes' },
          { name: 'Steering adjustment (up down)', value: 'Yes' },
          { name: 'Steering adjustment (up down)', value: 'Yes' },
          { name: 'Multi function steering wheel', value: 'AUDIO, PHONE' },
          { name: 'Seats', value: '5' },
          { name: 'Spare tire size', value: 'FULLSIZE' },
          { name: 'Keyless Entry', value: 'Yes' },
        ]
      },
      {
        title: 'Interior Comfort',
        features: [
          { name: 'Air conditioner', value: 'Yes' },
          { name: 'Electric windows', value: 'Yes' },
          { name: 'Electric window detail', value: 'FRONT' },
          { name: 'Radio', value: 'Yes' },
          { name: 'Remote side mirrors', value: 'Yes' }
        ]
      }
    ]
  }
]

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#e1e4e6'
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  heading: {
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '16px'
  },
  accordionHeading: {
    backgroundColor: '#f0f3f5',
    border: '1px solid #dfdfdf'
  },
  accordionBody: {
    flexDirection: 'column',
    padding: '0.5rem 0 0 0'
  }
}));

export const SpecificationsComponent = ({ propId }) => {
  const classes = useStyles();
  const { carId } = useParams();
  const [Id, setId] = useState(propId ? propId : carId)
  const [variantArr, setVariantArr] = useState(Id === 'kwid' ? tmpVariants : tmpVariantsTriber)
  const [loading, setLoading] = useState(false)

  const noDataComponent = () => {
    return (
      <Grid item xs={12} container alignContent='center' justify='center'>
        <Typography align='center' variant="button">
          No Variants available
        </Typography>
      </Grid>
    )
  }

  let tmpCarId = (carId).toLowerCase().replace(/ /gi, "%");

  let vehicleSpecs;
  switch (true) {
    case tmpCarId.indexOf("kwid") !== -1:
      vehicleSpecs = vehiclesSpecs.kwidSpecs
      break;
    case tmpCarId.indexOf("triber") !== -1:
      vehicleSpecs = vehiclesSpecs.triberSpecs
      break;
    case tmpCarId.indexOf("sandero") !== -1:
      vehicleSpecs = vehiclesSpecs.sanderoSpecs
      break;
    case tmpCarId.indexOf("clio") !== -1 && tmpCarId.indexOf("rs") === -1:
      vehicleSpecs = vehiclesSpecs.clioSpecs
      break;
    case tmpCarId.indexOf("kangoo") !== -1:
      vehicleSpecs = vehiclesSpecs.kongooSpecs
      break;
    case tmpCarId.indexOf("duster") !== -1 && tmpCarId.indexOf("techroad") !== -1:
      vehicleSpecs = vehiclesSpecs.dusterTechroadSpecs
      break;
    case tmpCarId.indexOf("captur") !== -1:
      vehicleSpecs = vehiclesSpecs.capturSpecs
      break;
    case tmpCarId.indexOf("duster") !== -1:
      vehicleSpecs = vehiclesSpecs.dusterSpecs
      break;
    case tmpCarId.indexOf("megane") !== -1 && tmpCarId.indexOf("rs") === -1:
      vehicleSpecs = vehiclesSpecs.meganeSpecs
      break;
    case tmpCarId.indexOf("trafic") !== -1:
      vehicleSpecs = vehiclesSpecs.traficSpecs
      break;
    case tmpCarId.indexOf("clio") !== -1 && tmpCarId.indexOf("rs") !== -1:
      vehicleSpecs = vehiclesSpecs.clioRSSpecs
      break;
    case tmpCarId.indexOf("koleos") !== -1:
      vehicleSpecs = vehiclesSpecs.koleosSpecs
      break;
    case tmpCarId.indexOf("megane") !== -1 && tmpCarId.indexOf("rs") !== -1:
      vehicleSpecs = vehiclesSpecs.meganeRSSpecs
      break;
    
    default:
      vehicleSpecs = noDataComponent()
  }

  return (
    <Grid xs={12} container>
      <Grid item xs={12}>
        {
          vehicleSpecs
          // (!loading && variantArr.length > 0) &&
          // variantArr.map((variant) =>
          //   <Accordion key={variant.id}>
          //     <AccordionSummary
          //       className={classes.accordionHeading}
          //       aria-controls="panel1a-content"
          //       id="panel1a-header"
          //     >
          //       <Box display="flex" style={{width: '100%'}} justifyContent="space-between">
          //         <Typography className={classes.heading} variant="h5" color="textPrimary">
          //           {variant.title}
          //         </Typography>
          //         <Typography className={classes.heading} variant="h5" color="textPrimary">
          //           <CurrencyValue value={variant.price}/>
          //         </Typography>
          //       </Box>
          //     </AccordionSummary>
          //     <AccordionDetails className={classes.accordionBody}>
          //       {
          //         variant.specs.map((spec) =>
          //           <Table aria-label="customized table">
          //             <TableHead>
          //               <TableRow>
          //                 <StyledTableCell colspan="2">{spec.title}</StyledTableCell>
          //                 {/*<StyledTableCell></StyledTableCell>*/}
          //               </TableRow>
          //             </TableHead>
          //             <TableBody>
          //               {spec.features.map((row) => (
          //                 <StyledTableRow key={row.name}>
          //                   <StyledTableCell component="th" scope="row">
          //                     {row.name}
          //                   </StyledTableCell>
          //                   <StyledTableCell align="right">{row.value}</StyledTableCell>
          //                 </StyledTableRow>
          //               ))}
          //             </TableBody>
          //           </Table>
          //         )
          //       }
          //     </AccordionDetails>
          //   </Accordion>
          // )
        }
      </Grid>

      {/* {
        (!loading && variantArr.length === 0 && Id) && noDataComponent()
      }
      {
        (!loading && !Id) && noDataComponent()
      } */}
    </Grid>
  );
};