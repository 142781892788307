export const BASE_URI = "https://conexa.r-e-d.co.za/api";
export const PAGE_SIZE = 20;
export const HAS_MULTIPLE_DEALERSHIPS = false;
export const QUALIFYING_FINANCE_PERCENTAGE = 0.33;
export const DEALERMAKEID = 8013;
export const DEALERID = 22;
export const MOTORGROUPID = 3;
export const VIRTUAL_URI_RANGE = `https://configurator.renaultmotors.co.za/${DEALERID}/range/:leadId`;
export const VIRTUAL_URI_DERIVATIVE = `https://configurator.renaultmotors.co.za/${DEALERID}/:modelId/builder/:leadId`;
export const VIRTUAL_URI_APPLICATION = `https://configurator.renaultmotors.co.za/${DEALERID}/1/vehicle/finance/0/:leadId`;
