import React, { useRef, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";
import Logo from "./shared/assets/logo.png";
import mobileLogo from "./shared/assets/logo.png";
import findDealer from "./shared/assets/Find-a-Dealer.svg";
import car from "./shared/assets/car.svg";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import HomeIcon from "@material-ui/icons/Home";
import Grid from "@material-ui/core/Grid";
import { BASE_URI, DEALERID } from "./shared/Constants";
import axios from "axios";
import { useMediaQuery as mq } from "react-responsive";
import CloseIcon from "@material-ui/icons/Close";
import OurVehiclesController from "./ourVehiclesPage/OurVehiclesController";

const drawerWidth = 240;

const navBarStyles = makeStyles((theme) => ({
  Container: {
    background: "#000000",
    color: "white",
  },
  link: {
    padding: "1rem !important",
    cursor: "pointer",
    minHeight: "64px",
    fontSize:'14px',
    textDecoration:'none',
    textTransform:"uppercase",
    // height: '64px',
    display: "flex",
    alignItems: "center",
    borderRadius: 0,
    fontFamily: "Renault",
    position: "relative",
    color: "white",
    whiteSpace: "nowrap",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "transparent",
    },
    "&:hover::after": {
      content: '" "',
      position: "absolute",
      display: "block",
      textDecoration: "none",
      borderBottom: "3px solid #EFDF00",
      width: "100%",
      left: 0,
      bottom: 0,
    },
  },
  iconLink: {
    padding: "1rem 0rem !important",
    cursor: "pointer",
    minHeight: "64px",
    height: "64px",
    display: "flex",
    alignItems: "center",
    borderRadius: 0,
    position: "relative",
    color: "white",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "transparent",
    },
    "&:hover::after": {
      content: '" "',
      position: "absolute",
      display: "block",
      textDecoration: "none",
      borderBottom: "3px solid #EFDF00",
      width: "100%",
      left: 0,
      bottom: 0,
    },
  },
  active: {
    textDecoration: "none",
  },
  background: {
    "@media (max-width: 769px)": {
      background: "#000000",
    },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#000",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  gutter: {
    "@media (max-width: 426px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  logo: {
    height: "auto",
    width: "40px",
    maxWidth: "40px",
    paddingTop: "17px",
    "@media (max-width: 717px)": {
      maxWidth: "40px",
      paddingTop: "17px",
      paddingBottom: " 12px",
    },
  },
  titleHolder: {
    padding: "0px 0px",
    textTransform: "uppercase",
    "@media (min-width: 600px)": {
      padding: "0px 0px",
    },
  },
  title: {
    fontWeight: "normal",
  },
  subTitleHolder: {
    color: "#D9D9D6",
    textTransform: "lowercase",
  },

  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  closeMenuButton: {
    marginRight: "auto",
    marginLeft: 0,
  },
  badgeButton: {
    "&:hover": {
      background: "transparent",
      color: "#5c92a7",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      color: "#5c92a7",
    },
  },
  flexContainer: {
    display: "flex",
    direction: "row",
  },
  grow: {
    flexGrow: 1,
  },
  root: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
      "@media (max-width: 410px)": {
        marginLeft: "3px",
      },
    },
  },
  iconButtonStyles: {
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
  },
  flexWrap: {
    "@media (max-width: 410px)": {
      flexWrap: "wrap",
    },
  },
}));

export default function NavbarComponent() {
  const classes = navBarStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [navBarData, setNavBarData] = useState(false);
  const anchorRef = useRef(null);
  let history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openNewDropdown = Boolean(anchorEl);

  const { vehicles } = OurVehiclesController();

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;

    axios({
      method: "GET",
      url: `${BASE_URI}/dealerWebsites/${DEALERID}`,
    }).then((response) => {
      setNavBarData({
        title: response.data.title,
        subTitle: response.data.subTitle,
        logo: response.data.image,
      });

      document.title = `${response.data.title}`;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const cleanModel = (model) => {
    return model.toLowerCase().replace(/\//g, "-").replace(/ /g, "-");
  };

  const menuLinks = (
    <React.Fragment>
      <Hidden xsDown implementation="css" className="h-100">
        <div className={classes.flexContainer}>
          <Link component={RouterLink} to="/">
            <IconButton aria-label="home" className={classes.link}>
              <HomeIcon />
            </IconButton>
          </Link>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            className={clsx(classes.link, classes.iconButtonStyles)}
            activeClassName={classes.active}
            onClick={handleDropdownClick}
          >
            Models
            <ArrowDropDownIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={openNewDropdown}
            onClose={handleDropdownClose}
          >
            {vehicles.map((vehicle) => (
              <MenuItem key={vehicle.id} onClick={handleDropdownClose}>
                <Link
                  color="textPrimary"
                  style={{ textTransform: "capitalize" }}
                  component={RouterLink}
                  to={{
                    pathname: "/models/" + cleanModel(vehicle.model),
                    state: {
                      model: {
                        id: vehicle.id,
                        price: vehicle.price,
                        modelName: vehicle.model,
                        fullModelName: vehicle.title,
                      },
                    },
                  }}
                  onClick={() => {
                    setMobileOpen(false);
                  }}
                >
                  {vehicle.model}
                </Link>
              </MenuItem>
            ))}
          </Menu>
          <Link
            variant="button"
            color="textSecondary"
            activeClassName={classes.active}
            onClick={() => setMobileOpen(false)}
            component={RouterLink}
            to="/used-vehicles"
            className={classes.link}
          >
            Used Cars
          </Link>
          <Link
            variant="button"
            color="textSecondary"
            activeClassName={classes.active}
            onClick={() => setMobileOpen(false)}
            component={RouterLink}
            to="/special-deals"
            className={classes.link}
          >
            Special Deals
          </Link>
          <Link
            variant="button"
            color="textSecondary"
            activeClassName={classes.active}
            onClick={() => setMobileOpen(false)}
            component={RouterLink}
            to="/apply-for-finance"
            className={classes.link}
          >
            Apply Online
          </Link>
          <Link
            variant="button"
            color="textSecondary"
            activeClassName={classes.active}
            onClick={() => setMobileOpen(false)}
            component={RouterLink}
            to="/contact-us"
            className={classes.link}
          >
            Contact Us
          </Link>
        
              <a   className={classes.link} target="_blank" onClick={() => setMobileOpen(false)} href="https://renaultcarcare.co.za/book-a-service?dealership=4681a20d8119420e9f1d9e8d70bddb7b">
                    Book A Service
                    </a>
    
          <Link
            variant="button"
            color="textSecondary"
            component={RouterLink}
            to="/parts"
            className={classes.link}
            onClick={() => setMobileOpen(false)}
          >
            Parts
          </Link>
        </div>
      </Hidden>
    </React.Fragment>
  );

  const isMobile = mq({ query: "(max-width: 717px)" });

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Container maxWidth="lg" className={classes.background}>
        <Grid
          container
          direction="row"
          className={`${classes.grid} ${classes.Container}`}
        >
          <React.Fragment>
            <Grid item xs={2} md={1} lg={1}>
              <Grid container direction="row">
                <Grid item xs={11} md={11} lg={12}>
                  <Toolbar className={classes.gutter}>
                    <Link component={RouterLink} to="/">
                      <img
                        src={Logo}
                        className={classes.logo}
                        alt="Renault Virtual"
                      />
                    </Link>
                  </Toolbar>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} md={9} lg={11}>
              <Grid container direction="row" style={{ paddingTop: 10 }}>
                <Grid item xs={11} md={10} lg={4}>
                  <Typography
                    variant="h5"
                    align={"left"}
                    className={classes.titleHolder}
                  >
                    <span className={classes.title}>Renault</span> Motors
                  </Typography>
                </Grid>
                <Grid item xs={1} md={2} lg={2}></Grid>
                <Grid item xs={11} md={10} lg={10}>
                  <Typography variant="subtitle1" align={"left"}>
                    Purchase your vehicle from the comfort of your own home
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        </Grid>

        <Toolbar
          className={`${classes.flexWrap} ${classes.Container}`}
          disableGutters
        >
          <div style={{ flex: 1, display: "flex" }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            {menuLinks}
          </div>
          <React.Fragment>
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                <IconButton
                  onClick={handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                <div className="d-flex flex-column flex-sm-row">
                  <Link
                    variant="button"
                    color="textSecondary"
                    component={RouterLink}
                    to="/"
                    className={classes.link}
                    onClick={() => setMobileOpen(false)}
                  >
                    Home
                  </Link>
                  <Link
                    variant="button"
                    color="textSecondary"
                    component={RouterLink}
                    to="/our-vehicles"
                    className={classes.link}
                    onClick={() => setMobileOpen(false)}
                  >
                    Models
                  </Link>
                  <Link
                    variant="button"
                    color="textSecondary"
                    component={RouterLink}
                    to="/special-deals"
                    onClick={() => setMobileOpen(false)}
                    className={classes.link}
                  >
                    Special Deals
                  </Link>
                  <Link
                    variant="button"
                    color="textSecondary"
                    component={RouterLink}
                    to="/apply-for-finance"
                    className={classes.link}
                    onClick={() => setMobileOpen(false)}
                  >
                    Apply Online
                  </Link>
                  <Link
                    variant="button"
                    color="textSecondary"
                    component={RouterLink}
                    to="/contact-us"
                    className={classes.link}
                    onClick={() => setMobileOpen(false)}
                  >
                    Contact Us
                  </Link>
            
                      <a   className={classes.link} target="_blank" onClick={() => setMobileOpen(false)} href="https://renaultcarcare.co.za/book-a-service?dealership=4681a20d8119420e9f1d9e8d70bddb7b">
                    Book A Service
                    </a>

                
                  
                  <Link
                    variant="button"
                    color="textSecondary"
                    component={RouterLink}
                    to="/parts"
                    className={classes.link}
                    onClick={() => setMobileOpen(false)}
                  >
                    Parts
                  </Link>
                </div>
              </Drawer>
            </Hidden>
            <div className={`${classes.right} ${classes.root}`}>
              <Link
                color="textPrimary"
                activeClassName={classes.active}
                className={classes.iconLink}
                onClick={() => setMobileOpen(false)}
                component={RouterLink}
                to="/our-vehicles"
              >
                {matches ? (
                  <img height="50" width="80" src={car} alt="Our vehicles" />
                ) : (
                  <img height="44" width="70" src={car} alt="Our vehicles" />
                )}
              </Link>
            </div>
          </React.Fragment>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
