import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
        'li': {
            display: 'block;'
        },
        'li: before': {
            content: 'counters(item, ".") ". "',
            counterIncrement: 'item'
        },
        'ol': {
            counterReset: 'item'
        },
        'li strong': {
            display: 'inline-block',
            margin: '10px 0'
        }
    },
    link: {
        color: '#7c7c7c',
        textDecoration: 'none',
        '&:hover': {
            color: '#1f2532',
            textDecoration: 'none'
        }
    }
}));

const Terms = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="lg">
            <div className={classes.content} >
                <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
                    PRIVACY POLICY
                </Typography>
                <p>
                <strong>Renault Motors</strong> recognises the importance of protecting your personal information. Therefore, we have put together a privacy policy and approach that respects and addresses your needs.
                <br/>
                <br/>
                We define personal information as any and all of the information specific to you that you provide to us over the Internet or any other channel. This includes personal data as varied as the information you enter into an online application for a new account, questions you ask via e-mail or personal banking data.
                <br/>
                <br/>
                How does LS Group use the personal information you provide? LS Group has the highest regard for the privacy of its customers. LS Group will use personal information as appropriate in the normal course of our business to provide the products and services you have requested. We will also use this information to provide positive identification of you when you contact us. On occasion, we also send e-mail updates on services and other materials we believe may be of importance or interest.
                <br/>
                <br/>
                Does LS Group provide your personal information to external parties? Absolutely not! LS Group does not and will not sell or provide personal information to third parties for independent use. We may, however, share personal information with business partners if that information is required to provide the product or service you have requested. They adhere to using information only as directed by LS Group.
                <br/>
                <br/>
                <strong>Renault Motors</strong> engages third parties that help it deliver its banner advertisements and other online communications. The third parties may collect and use information about LS Group customers to help us understand the offers, promotions, and types of advertising that are most appealing to its customers. The personal information they collect is aggregated and cannot be linked to a person. If you do not wish your personal information to be used in this way, please notify us.
                <br/>
                <br/>
                <strong>– Third party vendors, including Google and DoubleClick, show LS Group’s ads on sites on the internet.</strong>
                <br/>
                <br/>
                <strong>– Third party vendors, including Google and DoubleClick, use cookies to serve ads based on a user’s prior visits to LS Group’s website.</strong>
                <br/>
                <br/>
                <strong>– Users may opt out of Google and DoubleClick’s use of cookies by visiting the Google advertising opt-out page or by visiting the Network Advertising Initiative opt out page.</strong>
                </p>
            </div>
            
            <div>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Additional Links
                </Typography>
                    <ul>
                        <li>
                            <Link component={RouterLink} to="/requestergws" className={classes.link}>Personal information request form</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/privacy" className={classes.link}>Policies</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/cookies" className={classes.link}>Cookie Policy</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/terms" className={classes.link}>Terms & Conditions</Link>
                        </li>
                    </ul>
                </div>
        </Container>
    )
}

export default Terms