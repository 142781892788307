import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { BASE_URI, DEALERID } from "../shared/Constants";
import { FinanceContext } from "../shared/contexts/FinanceContext";

export default function ContactDetailsController() {
  const [contactDetailsLoading, setContactDetailsLoading] = useState(true);
  const [dealerContactDetails, setDealerContactDetails] = useState({});

  let { setGlobalDealerContactDetails } = useContext(FinanceContext);

  useEffect(() => {
    setContactDetailsLoading(true);
    setDealerContactDetails(null);
    setGlobalDealerContactDetails(null);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios
      .get(`${BASE_URI}/Dealers/DealerContactDetails/${DEALERID}`, {
        cancelToken: source.token,
      })
      .then((response) => {
        setDealerContactDetails(response?.data);
        setGlobalDealerContactDetails(response?.data);
        setContactDetailsLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        setContactDetailsLoading(false);
      });
  }, [DEALERID]);

  return { loading: contactDetailsLoading, dealerContactDetails };
}
