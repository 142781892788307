import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import * as tmpDeals from './tmpDeals'
import PaymentDetails from '../shared/PaymentDetails'
import FinanceOptionsComponent from './FinanceOptionsComponent'
import { ImageContainer } from '../shared/ImageContainer'
import Carousel from '../homePage/CarouselSingle'
import { SpecificationsComponent } from '../shared/SpecificationsComponent'
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BASE_URI } from '../shared/Constants';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CurrencyValue } from '../shared/TextMask';
import { FinanceContext } from '../shared/contexts/FinanceContext';
import CheckIcon from '@material-ui/icons/Check';
import ApplySteps from '../shared/ApplySteps';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";
import VehicleController from "../usedVehicles/VehicleController";
import ComingSoon from "../shared/assets/comingsoon.jpg";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(20, 0, 6),
    },
    largeButton: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%',
        '& h6': {
            fontSize: '28.8px'
        }
    },
    buttonIconLg: {
        fontSize: '5rem',
        color: '#EFDF00'
    },
    sectionPadding: {
        margin: '3rem 0'
    },
    paragraphPadding: {
        padding: '1.5rem 3rem !important'
    },
    heading: {
        borderBottom: '5px solid #EFDF00',
        textTransform: 'capitalize',
        paddingBottom: '.5rem',
        fontWeight: '700',
        display: 'inline-block'
    },
    yellowBorder: {
        borderBottom: '5px solid #EFDF00',
    },
    secondaryHeading: {
        textTransform: 'uppercase',
        paddingBottom: '1rem',
        fontWeight: '400'
    },
    slideMargin: {
        width: 'calc(100% - 20px)',
        height: 'calc(100%)',
        left: '10px',
        top: '10px',
    },
    topHeader: {
        fontWeight: 700,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '2.5rem'
    },
    yellow: {
        color: '#EFDF00'
    },
    specs: {
        '& .specs': {
            borderBottom: '0 !important',
            marginTop: '-25px'
        },
    },
    formControl: {
        minWidth: 400,
        '@media (max-width: 450px)': {
            minWidth: '100%'
        }
    },
    headerLine: {
        height: '2px',
        width: '100%',
        backgroundColor: '#EFDF00',
        padding: '0 !important'
    },
    smallHeading: {
        fontSize: '1.3rem'
    },
    lighter: {
        fontWeight: 'lighter',
        fontSize: '1.0rem',
        padding: '0 !important'
    },
    stepPaddingColours: {
        marginLeft: "1em",
        marginRight: "1em",
        marginTop: "0.5em",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    colorsContainer: {
        display: 'flex',
        '& > div:first-child': {
            marginLeft: 0
        }
    },
    colourName: {
        textTransform: 'uppercase',
        fontSize: '0.8rem',
        marginTop: '0.6rem'
    },
    colourPrice: {
        fontWeight: 700
    },
    stepOneButtons: {
        display: 'flex',
        '@media (max-width: 500px)': {
            flexDirection: 'column',
            alignItems: 'center',
            '& > a': {
                marginLeft: '0 !important',
                marginRight: '0 !important'
            }
        }
    },
    topDetailSection: {
        '@media screen and (max-width: 959px)': {
            display: 'flex',
            flexDirection: 'column',
            '& > div:first-child': {
                order: 2,
                marginTop: '2rem'
            },
            '& > div:nth-child(2)': {
                order: 3
            },
            '& > div:nth-child(3)': {
                order: 4
            },
            '& > div:nth-child(4)': {
                marginTop: '-1.5rem'
            }
        }
    },
    darkgreyleft: {
        fontWeight: 'bold',
        borderBottom: '#cccccc  1px solid',
    },
    darkgreyright: {
        textAlign: 'right',
        borderBottom: '#cccccc  1px solid',
    },
    flex: {
        display: 'flex',
        flexDirection: 'row!important',
        justifyContent: 'space-between',
    },
    flexM: {
       
        '@media (min-width: 800px)': {
            display: 'flex',
        flexDirection: 'row!important',
        justifyContent: 'space-between',
        },
    },
    ml: {
       
        '@media (min-width: 800px)': {
        marginLeft:'1rem'
        },
    },
    btn: {

        alignItems: 'start',
        marginTop: '2%',
        width: '45%',
    },
    btn1: {

        alignItems: 'start',
        marginTop: '2%',
        width: '45%',
        background: 'white',
        border: '1px solid black',
    },
    btn2: {

        alignItems: 'start',
        marginTop: '2%',
        width: '100%',
    },
}));

export default function CarProfileContainer({ searchLoading, activeVehicle, onFilterData }) {
    const classes = useStyles();
    const history = useHistory();

    let vehicleProfile;

    const [carProfile, setCarProfile] = useState(vehicleProfile);
    const [compLoading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [financeOptions, setFinanceOptions] = useState([])
    const [financeDetails, setFinanceDetails] = useState({})
    const [modelData, setModelData] = React.useState([]);
    const [images, setImages] = useState([]);
    const [activeImg, setActiveImg] = useState(0);
    
    let { setGlobalFinance, setGlobalVehicle, setGlobalColor, setGlobalVariantId, setGlobalModel, globalColor } = useContext(FinanceContext);

    const values =
    {
        model: "",
        modelId: "",
    };

    const calculateMonthly = (data) => {
        var deposit = data.purchasePrice * (data.deposit / 100);
        var totalPrice = data.purchasePrice;
        var initFee = 0;
        var principal = totalPrice - deposit + initFee;

        var balloonPerc = data.balloonPayment;

        var balloonAmt = totalPrice * balloonPerc / 100;

        var interestRate = data.linkedInterestRate;
        var interestPM = interestRate / 100 / 12;

        var repaymentPeriod = data.term;
        var days = 1;

        var v = 1 / (1 + interestPM);
        var d = 1 - v;
        var y = Math.pow(v, repaymentPeriod - 1);
        var comp = (1 - y) / d;
        var fp = principal * (Math.pow(1 + interestPM, (days / (365 / 12).toFixed())));
        var monthly = 0;

        if (parseInt(balloonAmt) > 0) {
            var comp = (1 - (Math.pow(v, repaymentPeriod - 1))) / d;
            monthly = (fp - (balloonAmt * Math.pow(v, repaymentPeriod - 1))) / comp;
        }
        else {
            var comp = (1 - (Math.pow(v, repaymentPeriod))) / d;
            monthly = fp / comp;
        }

        return round(monthly) + initFee;
    }

    function round(x) {
        return Math.round(x * 100) / 100;
    }

    useEffect(() => {

        const CancelToken = axios.CancelToken;
        setLoading(true)
        const source = CancelToken.source();

        axios.get(`${BASE_URI}/stock/${activeVehicle?.stockId}`, {
            cancelToken: source.token
        }).then(result => {

            let data = result.data
            setModelData(data);
            setGlobalModel(data);

            var tmpFinanceDetails = {
                monthlyInstallment: 0,
                term: 72,
                linkedInterestRate: 7,
                deposit: 0,
                balloonPayment: 0,
                originalPrice: data.price,
                purchasePrice: data.price
            };

            let monthly = calculateMonthly(tmpFinanceDetails);

            tmpFinanceDetails = { ...tmpFinanceDetails, monthlyInstallment: monthly };

            setFinanceDetails(tmpFinanceDetails);
            setGlobalFinance(tmpFinanceDetails);

            setImages(data.mainUrls.map(itm => { return { img: itm }; }));

            setLoading(false)
        });

    }, [activeVehicle]);

    const padZero = (str, len) => {
        len = len || 2;
        let zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    const invertColor = (hex) => {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        // invert color components
        let r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
            g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
            b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
        // pad each with zeros and return
        return '#' + padZero(r) + padZero(g) + padZero(b);
    }

    const stepsState = {
        one: {
            active: true,
            completed: false
        },
        two: {
            active: true,
            completed: false
        },
        three: {
            active: true,
            completed: false
        }
    }

    const getMainImage = () => {

        if (images.length > 0)
            return images[0].img ? images[0].img : ComingSoon;
        else
            return ComingSoon;

        return "";
    }

    return (
        <React.Fragment>
            {
                compLoading &&
                <Grid item container justifyContent='center' justify='center' alignContent='center' xs={12}>
                    <CircularProgress size={80} />
                </Grid>
            }
            {
                !compLoading &&
                <Container container  md={10} style={{ marginRight: '2rem' }} className={classes.flexM}>
                    <Grid item xs={12} md={7}>
                        <Box >
                            <img src={images[activeImg]?.img} alt='MainImage' width="100%"/>
                        </Box>
                      
                        {images.length > 1 &&
                            <Box style={{ marginBottom: '1rem' }}>
                                {

                                    <Carousel naturalSlideWidth={100}
                                        naturalSlideHeight={60}
                                        totalSlides={images.length}
                                        visibleSlides={4}
                                        isPlaying={true}
                                        infinite
                                        setActiveImg={setActiveImg}
                                        innerClass={classes.slideMargin}
                                        slides={images}
                                    />
                                }
                            </Box>
                        }
                        <Box className={classes.specs} mt={5}>
                            <Typography variant="h4" align="left" > Factory Features on this Vehicle</Typography>

                            {modelData.features}
                                       </Box>

                    </Grid>

                    <Grid item xs={12} md={5} className={classes.ml}>
                        <Formik
                            initialValues={values}
                            isInitialValid={false}
                            onSubmit={(values, actions) => {
                                setTimeout(() => {

                                    actions.setSubmitting(true);
                                }, 100);
                            }}
                        >
                            {(props) => {
                                const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;

                                return (
                                    <Form>
                                        <Box ml={1}>
                                            <Box mb={1} mt={1}>
                                                <Typography className={classes.smallHeading}>
                                                    {modelData.model}
                                       
                                                </Typography>
                                                <Typography className={classes.smallHeading}>
                                                <p className="clear">Stock No : {modelData.stockNo}</p>
                                                   
                                                </Typography>
                                            </Box>
                                            <div className={classes.headerLine}></div>
                                            <Box mb={1} mt={1}>
                                                <Typography variant="h4" color="textPrimary">
                                                    <strong>
                                                        <CurrencyValue value={financeDetails.purchasePrice} />
                                                    </strong>
                                                </Typography>

                                                <Typography variant="p" color="textPrimary">

                                                    <span className={classes.lighter}>
                                                        From <CurrencyValue value={financeDetails.monthlyInstallment} /> pm
                                                        </span>
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <PaymentDetails displayPrice={false} details={financeDetails ? financeDetails : carProfile.dealDetails} />

                                        <Box mb={3} mt={3} className={`${classes.stepOneButtons} ${classes.flex}`} style={{ width: 'initial', display: 'flex' }}>

                                            <Button variant="contained" gutterBottom onClick={() => { history.push(`/contact-us`) }} color="primary" className={classes.btn}>
                                                Request Call back
                                                            </Button>

                                            <Button variant="contained" gutterBottom onClick={() => { history.push(`/apply-for-finance`) }} className={classes.btn1}>
                                                Pre-Qualify Now
                                                            </Button>




                                        </Box>


                                        <Box style={{ marginTop: 24 }}>
                                            <table border="0" width="100%" cellspacing="0px" cellpadding="15px">
                                                <tbody>

                                                    <tr >
                                                        <td className={classes.darkgreyleft}>
                                                            Year
                                                                        </td>
                                                        <td className={classes.darkgreyright}>
                                                            {modelData.year}
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td className={classes.darkgreyleft}>
                                                            Mileage
                                                                         </td>
                                                        <td className={classes.darkgreyright}>
                                                            {modelData.mileage} km
                                                                         </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={classes.darkgreyleft}>
                                                            Colour
                                                                         </td>
                                                        <td className={classes.darkgreyright}>
                                                            {modelData.colour}
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td className={classes.darkgreyleft}>
                                                            Body
                                                                         </td>
                                                        <td className={classes.darkgreyright}>
                                                            {modelData.bodyType}
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td className={classes.darkgreyleft}>
                                                            Transmission
                                                                         </td>
                                                        <td className={classes.darkgreyright}>
                                                            {modelData.transmission == "M" ? "Manual" : "Auto"}
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td className={classes.darkgreyleft}>
                                                            Fuel
                                                                         </td>
                                                        <td className={classes.darkgreyright}>
                                                            {modelData.fuelType == "D" ? "Diesel" : "Unleaded"}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Box>
                                        <Box>
                                            <Typography style={{ marginTop: '.5rem' }} variant="caption" color="textSecondary">
                                                {financeDetails.termsAndConditions}
                                            </Typography>
                                        </Box>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Grid>
                </Container>
            }
        </React.Fragment>
    );
}