import React from 'react';
import Box from '@material-ui/core/Box';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PhoneIcon from '@material-ui/icons/Phone';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    steps: {
        display: 'flex',
        padding: '1rem',
        '@media (max-width: 400px)': {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    stepsIcon: {
        '& svg': {
         fontSize: '5rem',
         border: '2px solid #EFDF00',
         borderRadius: '1rem',
         padding: '0.4rem'
        }
    },
    stepsText: {
        marginLeft: '1.5rem',
        '@media (max-width: 400px)': {
            marginLeft: 0,
            textAlign: 'center'
        }
    },
    stepsTitle: {
        position: 'relative'
    },
    stepsTextCenter: {
        textAlign: 'center'
    },
    yellow: {
        color: '#EFDF00'
    },
    horizontalSteps: {
        display: 'flex',
        '& > div': {
            flexDirection: 'column',
            alignItems: 'center',
            flex: '1 1 0'
        },
        '@media (max-width: 780px)': {
            flexDirection: 'column'
        }
    },
    stepDisabled: {
        opacity: '0.3'
    },
    checkIcon: {
        position: 'absolute',
        top: '-10px',
        '& > svg': {
            fontSize: '3rem',
            opacity: '1',
            color: '#008800'
        }
    }
}));

const ApplySteps = ({horizontal, disabledStates}) => {
    const classes = useStyles();

    return (
        <Box className={horizontal && classes.horizontalSteps}>
            <Box className={`${classes.steps} ${!disabledStates.one.active && classes.stepDisabled}`}>
                <Box className={classes.stepsIcon}>
                    <AllInboxIcon />    
                </Box>
                <Box className={horizontal ? classes.stepsTextCenter : classes.stepsText}>
                    <Typography className={classes.stepsTitle} variant="h5"><span style={{textTransform: 'uppercase'}} className={classes.yellow}>Step 1</span> - Basic Info {disabledStates.one.completed && <span className={classes.checkIcon}><CheckIcon /></span> }</Typography>
                    <Typography>Complete the short online form to receive a real-time pre-approval</Typography>
                </Box>
            </Box>
            <Box className={`${classes.steps} ${!disabledStates.two.active && classes.stepDisabled}`}>
                <Box className={classes.stepsIcon}>
                    <PlaylistAddCheckIcon />          
                </Box>
                <Box className={horizontal ? classes.stepsTextCenter : classes.stepsText}>
                    <Typography className={classes.stepsTitle} variant="h5"><span style={{textTransform: 'uppercase'}} className={classes.yellow}>Step 2</span> - Approve-It&#8482; {disabledStates.two.completed && <span className={classes.checkIcon}><CheckIcon /></span> }</Typography>
                    <Typography>To ensure the protection of your data, complete the <b>Nedbank Approve-It&#8482;</b> process</Typography>
                </Box>
            </Box>
            <Box className={`${classes.steps} ${!disabledStates.three.active && classes.stepDisabled}`}>
                <Box className={classes.stepsIcon}>
                    <PhoneIcon />          
                </Box>
                <Box className={horizontal ? classes.stepsTextCenter : classes.stepsText}>
                    <Typography className={classes.stepsTitle} variant="h5"><span style={{textTransform: 'uppercase'}} className={classes.yellow}>Step 3</span> - Validations {disabledStates.three.completed && <span className={classes.checkIcon}><CheckIcon /></span> }</Typography>
                    <Typography>Our qualified Sales team will contact you to get
                        the required documentation to conclude your transaction
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

ApplySteps.defaultProps = {
    disabledStates: {
        one: true,
        two: true,
        three: true
    }
}   

export default ApplySteps;